import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { TABS } from "../child/DashboardContent";
type Props = {
  activeTab: string;
  changeActiveTab: (tabName: string) => void;
};

const SubHeader: FunctionComponent<Props> = ({
  activeTab,
  changeActiveTab,
}) => {
  const { t } = useTranslation();

  return (
    <div className="par-ecom-widget__nav">
      <ul className="par-ecom-widget__nav-list">
        <li>
          <a
            className={`par-ecom-widget__nav-link ${
              activeTab === TABS.HOME ? "nav-link--active" : ""
            }`}
            onClick={(event) => {
              event.preventDefault();
              changeActiveTab(TABS.HOME);
            }}
            data-id="tab1"
            href="javaacript:;"
          >
            {t("home")}
          </a>
        </li>
        <li>
          <a
            href="javaacript:;"
            className={`par-ecom-widget__nav-link ${
              activeTab === TABS.EARNPOINTS ? "nav-link--active" : ""
            }`}
            onClick={(event) => {
              event.preventDefault();
              changeActiveTab(TABS.EARNPOINTS);
            }}
            data-id="tab2"
          >
            {t("earn_points")}
          </a>
        </li>
        <li>
          <a
            href="javaacript:;"
            className={`par-ecom-widget__nav-link ${
              activeTab === TABS.REWARDS ? "nav-link--active" : ""
            }`}
            onClick={(event) => {
              event.preventDefault();
              changeActiveTab(TABS.REWARDS);
            }}
            data-id="tab3"
          >
            {t("rewards")}
          </a>
        </li>
      </ul>
    </div>
  );
};

export default SubHeader;
