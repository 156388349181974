import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { RULES } from "../../../../../types/Types";
import { ReactComponent as BirthdayImage } from "../../../../../assets/images/birthday.svg";
import { ReactComponent as PlaceOrderImage } from "../../../../../assets/images/place_order.svg";
import { ReactComponent as CreateAccountImage } from "../../../../../assets/images/create_account.svg";
import { ReactComponent as ReviewProductImage } from "../../../../../assets/images/review_product.svg";
import { ReactComponent as ReferFriendsImage } from "../../../../../assets/images/refer_friend.svg";
import { ReactComponent as VisitWebSiteImage } from "../../../../../assets/images/visit_website.svg";
import { ReactComponent as PurchaseProductImage } from "../../../../../assets/images/purchase_product.svg";
import { MODALS, TABS } from "../../DashboardContent";

type Props = {
  activeTab: string;
  rules?: RULES[];
  toggleOpenClose: (modal: string, data: any) => void;
};

const EarnPointsTab: FunctionComponent<Props> = ({
  activeTab,
  rules,
  toggleOpenClose,
}) => {
  const { t } = useTranslation();

  const openModal = (rule: RULES) => {
    switch (rule.name) {
      case "birthday":
        return toggleOpenClose(MODALS["BIRTHDAYMODAL"], rule);
      default:
        return toggleOpenClose(MODALS["ORDERMODAL"], rule);
    }
  };

  const getImages = (name: string) => {
    switch (name) {
      case "birthday":
        return <BirthdayImage />;
      case "place_order":
        return <PlaceOrderImage />;
      case "create_account":
        return <CreateAccountImage />;
      case "review_product":
        return <ReviewProductImage />;
      case "refer_friend":
        return <ReferFriendsImage />;
      case "visit_website":
        return <VisitWebSiteImage />;
      case "purchase_product":
        return <PurchaseProductImage />;
      default:
        break;
    }
  };
  return (
    <div
      className={`par-ecom-widget__nav-tab ${activeTab === TABS.EARNPOINTS ? "nav-tab--active" : ""
        }`}
    >
      <div className="nav-tab--earn-points">
        <ul className="par-ecom-widget__list">
          {rules?.map((rule, index) => {
            return (
              <li
                key={index}
                className="par-ecom-widget__list-item par-ecom-widget__list-order accent-color"
              >
                <span className="par-ecom-widget__list-leading-icon">
                  {getImages(rule.name)}
                </span>
                <div className="par-ecom-widget__list-content">
                  <span className="par-ecom-widget__list-label">
                    {rule.title}
                  </span>
                  <span className="par-ecom-widget__list-help-text">
                    {rule?.value} {t("points")}
                  </span>
                </div>
                <span className="par-ecom-widget__list-trailing-icon">
                  {rule.rule_completed === "yes" ? (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="24" height="24" rx="12" fill="#2196F3" />
                      <path
                        d="M10.1154 17C9.87348 16.9993 9.64276 16.903 9.47826 16.7343L5.23658 12.4417C4.90639 12.1069 4.92398 11.5809 5.27585 11.2668C5.62773 10.9527 6.18065 10.9694 6.51083 11.3042L10.1067 14.9492L17.4467 7.31043C17.6497 7.06999 17.976 6.95637 18.2941 7.01535C18.6123 7.07434 18.8699 7.29622 18.9631 7.59157C19.0563 7.88691 18.9698 8.20708 18.7384 8.42303L10.7612 16.726C10.5983 16.8978 10.3673 16.9971 10.1241 17H10.1154Z"
                        fill="white"
                      />
                    </svg>
                  ) : (
                    <span
                      className="par-ecom-widget__list-trailing-icon"
                      onClick={() => openModal(rule)}
                    >
                      <svg
                        width="6"
                        height="12"
                        viewBox="0 0 6 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.851447 11.9984C0.652855 11.9988 0.460396 11.9292 0.30748 11.8017C0.13366 11.6567 0.0243216 11.4481 0.0035921 11.2219C-0.0171374 10.9956 0.0524454 10.7704 0.196987 10.5958L4.00476 6.01173L0.332979 1.41906C0.190382 1.24237 0.123663 1.01578 0.147592 0.789442C0.17152 0.563109 0.284124 0.355709 0.460471 0.213163C0.638252 0.0557642 0.873229 -0.0198705 1.10879 0.00448179C1.34435 0.028834 1.55913 0.150965 1.7014 0.34145L5.80665 5.47292C6.06445 5.78851 6.06445 6.2435 5.80665 6.55909L1.5569 11.6906C1.38397 11.9005 1.12193 12.0148 0.851447 11.9984Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  )}
                </span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default EarnPointsTab;
