import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useFaqs } from "../../api/hooks";
import Loader from "../../components/Loader";

type Props = {
  storeUrl: string;
};
const Faq: FunctionComponent<Props> = ({ storeUrl }) => {
  const { t } = useTranslation();
  const { data } = useFaqs(storeUrl);

  if (data === undefined) {
    return <Loader />;
  }

  const faqData = data?.data;
  return (
    <div className="par-ecom-widget__faq">
      <h2 className="par-ecom-widget__faq-title">
        {t("frequently_asked_question")}
      </h2>
      {faqData.length ? (
        faqData.map((faq, index) => {
          return (
            <div className="par-ecom-widget__faq-wrap accent-color">
              <h5 className="par-ecom-widget__faq-que">{faq.faqTitle}</h5>
              <div className="par-ecom-widget__faq-ans">
                <p>{faq.faqAnswer}</p>
              </div>
            </div>
          );
        })
      ) : (
        <div>{t("no_faq_found")}</div>
      )}
    </div>
  );
};

export default Faq;
