import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { REWARDS_AVAILABLE } from "../../../../../types/Types";
import { TABS, MODALS } from "../../DashboardContent";

type Props = {
  activeTab: string;
  rewards?: REWARDS_AVAILABLE[];
  toggleOpenClose: (modal: string, data: REWARDS_AVAILABLE) => void;
  refetchCustomer: () => void;
};

const RewardsTab: FunctionComponent<Props> = ({
  activeTab,
  rewards,
  toggleOpenClose,
  refetchCustomer,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={`par-ecom-widget__nav-tab ${
        activeTab === TABS.REWARDS ? "nav-tab--active" : ""
      }`}
    >
      <div className="nav-tab--rewards">
        <ul className="par-ecom-widget__list">
          {rewards?.length ? (
            rewards?.map((reward, index) => {
              return (
                <li
                  key={"reward" + reward._id}
                  className="par-ecom-widget__list-item accent-color"
                >
                  <span className="par-ecom-widget__list-leading-icon">
                    <svg
                      width="28"
                      height="27"
                      viewBox="0 0 28 27"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M20.8002 25.8533C20.99 25.9505 21.2003 26.0008 21.4135 25.9999C21.7081 26.0135 21.9988 25.9291 22.2402 25.7599C22.6626 25.4574 22.8707 24.937 22.7735 24.4266L21.4402 16.9199L26.9335 11.5866C27.2828 11.2381 27.411 10.7252 27.2669 10.2533C27.1092 9.76984 26.6903 9.41817 26.1869 9.34661L18.5869 8.23995L15.2002 1.41328C14.9774 0.953292 14.5113 0.661133 14.0002 0.661133C13.4891 0.661133 13.023 0.953292 12.8002 1.41328L9.45354 8.25328L1.85354 9.35995C1.3501 9.4315 0.931198 9.78318 0.773539 10.2666C0.629385 10.7385 0.757614 11.2514 1.10687 11.5999L6.60021 16.9333L5.26687 24.4399C5.17785 24.9411 5.38189 25.4492 5.79278 25.7495C6.20367 26.0499 6.74972 26.0902 7.20021 25.8533L14.0002 22.2933L20.8002 25.8533ZM14.6135 19.6133C14.4254 19.5118 14.2139 19.4612 14.0002 19.4666C13.7773 19.4223 13.5465 19.4408 13.3335 19.5199L8.30687 22.1866L9.26687 16.6399C9.3425 16.2048 9.1977 15.7604 8.88021 15.4533L4.88021 11.5466L10.4802 10.7199C10.9111 10.6576 11.2844 10.3888 11.4802 9.99995L14.0002 4.99995L16.5202 9.99995C16.6988 10.3854 17.0504 10.6628 17.4669 10.7466L23.0669 11.5733L19.0669 15.4799C18.7494 15.787 18.6046 16.2314 18.6802 16.6666L19.6402 22.2799L14.6135 19.6133Z"
                        fill="#74CAFF"
                      />
                    </svg>
                  </span>
                  <div className="par-ecom-widget__list-content">
                    <span className="par-ecom-widget__list-label">
                      {reward.title}
                    </span>
                    <span className="par-ecom-widget__list-help-text">
                      {reward.points_cost} {t("points")}
                    </span>
                  </div>
                  <div
                    className="par-ecom__btn-redeem primary-bg-color"
                    onClick={() => {
                      toggleOpenClose(MODALS["DISCOUNTMODAL"], reward);
                    }}
                  >
                    {t("redeem")}
                  </div>
                </li>
              );
            })
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {t("no_rewards_found")}
            </div>
          )}
        </ul>
      </div>
    </div>
  );
};

export default RewardsTab;
