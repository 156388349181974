import React, { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { UPDATE_BIRTHDAY_API_URL } from "../../../../../api/ApiConstant";
import axiosInstance from "../../../../../utils/axios";
import { MODALS } from "../../DashboardContent";

type Props = {
  open: boolean;
  toggleOpenClose: (modal: string, data: any) => void;
  storeUrl: string;
  customerId: string;
  dateOfBirth: {
    day: number;
    month: number;
    year: number;
  } | null;
  title: string;
};

const BirthdayModal: FunctionComponent<Props> = ({
  open,
  toggleOpenClose,
  storeUrl,
  customerId,
  dateOfBirth,
  title,
}) => {
  const getDate = () => {
    if (dateOfBirth !== null) {
      return `${dateOfBirth.year}-${dateOfBirth.month}-${dateOfBirth.day}`;
    }
    return "";
  };
  const { t } = useTranslation();
  const [birthday, setBirthday] = useState<string>(getDate());
  const [error, setError] = useState<string>("");
  const [successMsg, setSuccessMsg] = useState<string>(
    dateOfBirth !== null ? getDate() : ""
  );
  const updateBirthday = async () => {
    try {
      if (birthday === undefined) {
        setError(t("birthday_is_required"));
        return false;
      } else {
        setError("");
      }
      const [year, month, day] = birthday.split("-");

      const result = await axiosInstance.put(
        `${UPDATE_BIRTHDAY_API_URL}${customerId}`,
        {
          year: Number(year),
          month: Number(month),
          day: Number(day),
          mwb_app_store_url: storeUrl,
        }
      );
      if (result.data.success) {
        setSuccessMsg(result.data.message);
      }
    } catch (error: any) {
      setError(error.message);
    }
  };

  return (
    <div
      className={`par-ecom-widget__modal modal-bday ${
        open ? "modal-order--open" : ""
      }`}
    >
      <h3 className="par-ecom-widget__modal-title">{title}:</h3>
      <span
        className="par-ecom-widget__modal-close"
        onClick={() => toggleOpenClose(MODALS["BIRTHDAYMODAL"], "")}
      >
        <svg
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="#000000"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.99943 3.06091L1.14179 0.197009C0.880587 -0.0641537 0.457099 -0.0641537 0.1959 0.197009C-0.0652999 0.458171 -0.0652999 0.881599 0.1959 1.14276L3.06021 4L0.1959 6.85724C-0.0652999 7.1184 -0.0652999 7.54183 0.1959 7.80299C0.457099 8.06415 0.880587 8.06415 1.14179 7.80299L3.99943 4.93909L6.85708 7.80299C6.98215 7.92908 7.15241 8 7.33002 8C7.50763 8 7.67789 7.92908 7.80296 7.80299C7.92907 7.67793 8 7.5077 8 7.33012C8 7.15253 7.92907 6.9823 7.80296 6.85724L4.93866 4L7.80296 1.14276C7.92907 1.0177 8 0.847472 8 0.669885C8 0.492298 7.92907 0.322065 7.80296 0.197009C7.67789 0.0709219 7.50763 0 7.33002 0C7.15241 0 6.98215 0.0709219 6.85708 0.197009L3.99943 3.06091Z"
            fill="#000000"
          />
        </svg>
      </span>
      <div className="par-ecom-widget__modal-form form-bday">
        <div className="par-ecom-widget__modal-form-group">
          {dateOfBirth === null ? (
            <label htmlFor="Birthday">{t("enter_your_birthday")}</label>
          ) : null}

          <div
            style={{ cursor: "pointer" }}
            className="par-ecom-widget__modal-form-field"
          >
            <input
              disabled={dateOfBirth !== null}
              type="date"
              placeholder="DD - MM - YYYY"
              name="birthday"
              id="birthday"
              className="accent-color"
              value={birthday}
              onChange={(event) => setBirthday(event.target.value)}
            />

            {dateOfBirth === null ? (
              <>
                <input
                  type="submit"
                  className="primary-bg-color"
                  onClick={updateBirthday}
                  value={t("save")}
                />
                {error ? <span className="error">{error}</span> : ""}
                {successMsg ? (
                  <span className="success">{successMsg}</span>
                ) : (
                  ""
                )}
              </>
            ) : (
              <input
                disabled
                type="submit"
                className="primary-bg-color"
                onClick={updateBirthday}
                value={t("save")}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default BirthdayModal;
