import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import useLocales from "../../../locales/useLocales";
import { TABS } from "../child/DashboardContent";

type Props = {
  userName?: string;
  pointBalance?: {
    available_points: number;
    pending_points: number;
  };
  changeActiveTab: (tab: string) => void;
  openCloseWidget: () => void;
};
const Header: FunctionComponent<Props> = ({
  userName,
  pointBalance,
  changeActiveTab,
  openCloseWidget,
}) => {
  const { t } = useTranslation();
  const { onChangeLang, currentLang, allLang } = useLocales();
  const onChangeLanguage = (event: any) => {
    onChangeLang(event.target.value);
  };

  return (
    <div>
      <header className="par-ecom-widget__header primary-bg-color">
        <h1 className="par-ecom-widget__header-title">
          {t("welcome")}, {userName} {pointBalance?.available_points}{" "}
          {t("points")} ({pointBalance?.pending_points} {t("pending")})
          <span></span>
        </h1>
        <div className="par-ecom-widget__header-ls">
          <select onClick={onChangeLanguage}>
            {allLang.map((lang, index) => {
              return (
                <option
                  key={lang.sytemValue}
                  value={lang.value}
                  selected={currentLang.value === lang.value}
                >
                  {lang.icon} {lang.sytemValue}
                </option>
              );
            })}
          </select>
        </div>
        <div
          className="par-ecom-widget__header-faq"
          onClick={() => changeActiveTab(TABS.BLANK)}
        >
          <span className="par-ecom-widget__header-faq-icon">
            <svg
              width="14"
              height="12"
              viewBox="0 0 14 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.2628 10.563C13.2622 10.5613 13.2617 10.5596 13.2611 10.5579L12.7328 8.95281V5.02233C12.7328 4.37785 12.2085 3.85352 11.564 3.85352H8.15207V5.76596C8.15207 6.8401 7.27819 7.71399 6.20404 7.71399H5.92773V9.59371C5.92773 10.2382 6.45207 10.7625 7.09655 10.7625H11.0618L12.0824 11.4417C12.2166 11.5325 12.3683 11.5777 12.5197 11.5777C12.6821 11.5777 12.8441 11.5257 12.9837 11.4222C13.254 11.2216 13.3635 10.8843 13.2628 10.563ZM10.6107 9.17893C10.4094 9.25493 10.1846 9.1533 10.1087 8.95198L9.93901 8.50242H8.753L8.58137 8.95309C8.52217 9.10852 8.3742 9.20413 8.21719 9.20413C8.17108 9.20413 8.12423 9.19589 8.07859 9.17852C7.8775 9.10192 7.7766 8.87683 7.85317 8.67574L8.92606 5.85869C8.92718 5.85573 8.92835 5.85277 8.92954 5.84983C8.99965 5.67851 9.16453 5.56768 9.34964 5.5675C9.53577 5.56653 9.69987 5.67781 9.77029 5.84902C9.77174 5.85256 9.77312 5.85609 9.77447 5.85967L10.8376 8.67684C10.9137 8.87816 10.812 9.10296 10.6107 9.17893Z"
                fill="white"
              />
              <path
                d="M9.04883 7.72341H9.644L9.34776 6.93848L9.04883 7.72341Z"
                fill="white"
              />
              <path
                d="M6.20309 0H1.73561C1.09112 0 0.56679 0.524332 0.56679 1.16882V5.12537L0.0385361 6.73054C0.0379647 6.73228 0.0374193 6.73405 0.0368738 6.73579C-0.0638263 7.05698 0.0457828 7.39412 0.316117 7.59469C0.455674 7.69822 0.617646 7.75022 0.779956 7.75022C0.931331 7.75022 1.08299 7.70503 1.21707 7.61425L2.23778 6.93501H6.20306C6.84755 6.93501 7.37188 6.41068 7.37188 5.76619V1.16884C7.3719 0.524332 6.84757 0 6.20309 0ZM5.59546 5.19835C5.51938 5.27441 5.41967 5.31246 5.31998 5.31246C5.22027 5.31246 5.12058 5.27443 5.0445 5.19833L4.87391 5.02771C4.58827 5.21474 4.24732 5.32396 3.88114 5.32396C2.879 5.32396 2.06368 4.50865 2.06368 3.5065C2.06368 2.50436 2.879 1.68905 3.88114 1.68905C4.88328 1.68905 5.6986 2.50436 5.6986 3.5065C5.6986 3.86102 5.59621 4.19187 5.41998 4.47179L5.59551 4.64732C5.74761 4.79953 5.74761 5.0462 5.59546 5.19835Z"
                fill="white"
              />
              <path
                d="M3.882 2.46875C3.30951 2.46875 2.84375 2.93451 2.84375 3.507C2.84375 4.07948 3.30951 4.54525 3.882 4.54525C4.03163 4.54525 4.17386 4.51317 4.30251 4.45592L4.13181 4.28522C3.97966 4.13307 3.97966 3.8864 4.13181 3.73424C4.28397 3.58209 4.53064 3.58209 4.68279 3.73424L4.8444 3.89585C4.89315 3.77567 4.92024 3.64445 4.92024 3.507C4.92024 2.93449 4.45448 2.46875 3.882 2.46875Z"
                fill="white"
              />
            </svg>
          </span>
          <span className="par-ecom-widget__header-faq-text">{t("faq")}</span>
        </div>
        <span className="par-ecom-widget--close" onClick={openCloseWidget}>
          <svg
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.99943 3.06091L1.14179 0.197009C0.880587 -0.0641537 0.457099 -0.0641537 0.1959 0.197009C-0.0652999 0.458171 -0.0652999 0.881599 0.1959 1.14276L3.06021 4L0.1959 6.85724C-0.0652999 7.1184 -0.0652999 7.54183 0.1959 7.80299C0.457099 8.06415 0.880587 8.06415 1.14179 7.80299L3.99943 4.93909L6.85708 7.80299C6.98215 7.92908 7.15241 8 7.33002 8C7.50763 8 7.67789 7.92908 7.80296 7.80299C7.92907 7.67793 8 7.5077 8 7.33012C8 7.15253 7.92907 6.9823 7.80296 6.85724L4.93866 4L7.80296 1.14276C7.92907 1.0177 8 0.847472 8 0.669885C8 0.492298 7.92907 0.322065 7.80296 0.197009C7.67789 0.0709219 7.50763 0 7.33002 0C7.15241 0 6.98215 0.0709219 6.85708 0.197009L3.99943 3.06091Z"
              fill="white"
            />
          </svg>
        </span>
      </header>
    </div>
  );
};

export default Header;
