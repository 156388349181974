import { useState } from "react";
import { GLOBAL_OBJECT_TYPE } from "../types/Types";

const useGlobalObject = (): GLOBAL_OBJECT_TYPE | null => {
  const [state, setState] = useState<GLOBAL_OBJECT_TYPE | null>(null);

  window.addEventListener("message", function (e) {
    if (e.data.mwb_app_details !== undefined) {
      const globalObject: GLOBAL_OBJECT_TYPE = {
        apiKey: e.data.mwb_app_details.mwb_app_api_key ?? "",
        customerEmail: e.data.mwb_app_details.mwb_app_customer_email ?? "",
        customerId: e.data.mwb_app_details.mwb_app_customer_id ?? "",
        customerUserName:
          e.data.mwb_app_details.mwb_app_customer_username ?? "",
        storeLoginUrl: e.data.mwb_app_details.mwb_app_store_login_url ?? "",
        storeRegisterUrl:
          e.data.mwb_app_details.mwb_app_store_register_url ?? "",
        storeUrl: e.data.mwb_app_details.mwb_app_store_url ?? "",
        widgetLang: e.data.mwb_app_details.mwb_app_widget_language ?? "",
      };
      setState({...globalObject});
    }
  });
  return state;
};

export default useGlobalObject;
