const en = {
  introducing_points_and_rewards : 'Introducing Loyalty Reward Points',
  sign_up_to: 'Sign up to',
  store: 'store',
  to_start_earning_rewards: 'to start earning rewards',
  sign_up: 'Sign Up',
  already_have_an_account: 'Already have an account',
  login: 'Login',
  home: 'Home',
  earn_points: 'Earn Points',
  rewards: 'Rewards',
  earn_points_by_completing_rules: 'Earn Points by Completing Rules',
  your_active_rewards:'Your Active Rewards',
  your_activity: 'Your Activity',
  refer_your_friends: 'Refer your friends',
  friend_free_shiping: 'Give a friend free shipping on their first purchase and earn 1,000 points if they spend over ₹20',
  your_unique_referral_code: 'Your unique referral code',
  copy:'COPY',
  points: 'Points',
  welcome:'Welcome',
  pending:'Pending',
  off_discount: 'Off Discount',
  redeem:'Redeem',
  code_copied_sucessfully: 'Code Copied Sucessfully !',
  earn_point_by_referring_a_friend: 'Earn points by referring to your friends',
  enter_your_birthday:'Enter your Birthday',
  enjoy_birthday: 'Enjoy Birthday',
  save: 'SAVE',
  faq: 'FAQ',
  birthday_is_required: '*Birthday is required',
  date: 'Date',
  activity: 'Activity',
  status: 'Status',
  use_this_coupon_at_checkout: 'Use this code at Checkout',
  frequently_asked_question :'Frequently Asked Questions',
  no_faq_found: 'No Faq Found',
  no_rewards_found: 'No Reward Found',
  limit_reached: 'Limit Reached. You cannot use this coupon anymore.',
  name: 'Name',
  url: 'Url',
  loading: 'Loading',
  user_blocked: 'You are blocked. You cannot use points for redeeming reward.',
};

export default en;
