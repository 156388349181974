import { useTranslation } from "react-i18next";
import "./Loader.scss";

export default function Loader() {
  const { t } = useTranslation();
  return (
    <div className="par-ecom__loader">
      <div className="par-ecom__loader-container">
        <span className="par-ecom__loader-spin"></span>
        <span className="par-ecom__loader-text">{t("loading")}</span>
      </div>
    </div>
  );
}
