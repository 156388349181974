import { useTranslation } from 'react-i18next';
import { setNavHeight } from '../containers/theme/js/custom';

const LANGS = [
  {
    label: 'English',
    value: 'en',
    sytemValue: 'EN',
    icon: '🇳🇱',
  },
  {
    label: 'French',
    value: 'fr',
    sytemValue: 'FR',
    icon: '🇫🇷',
  },
];

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const langStorage = localStorage.getItem('i18nextLng');
  const currentLang =
    LANGS.find(_lang => _lang.value === langStorage) || LANGS[0];

  const handleChangeLanguage = (newlang: string) => {
    i18n.changeLanguage(newlang);
    setTimeout(() => {
      setNavHeight();
    }, 50);
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: LANGS,
  };
}
