const en = {
  introducing_points_and_rewards : 'Présentation des points de fidélité',
  sign_up_to: 'Inscrivez-vous pour',
  store: 'boutique',
  to_start_earning_rewards: 'pour commencer à gagner des récompenses',
  sign_up: "S'inscrire",
  already_have_an_account: "Vous avez déjà un compte",
  login: 'Connexion',
  home: 'Maison',
  earn_points: 'Gagnez des points',
  rewards: 'Récompenses',
  earn_points_by_completing_rules: 'Gagnez des points en remplissant les règles',
  your_active_rewards:'Vos récompenses actives',
  your_activity: 'Votre activité',
  refer_your_friends: 'Parrainez vos amis',
  friend_free_shiping: "Offrez à un ami la livraison gratuite sur son premier achat et gagnez 1 000 points s'il dépense plus de 20 ₹",
  your_unique_referral_code: 'Votre code de parrainage unique',
  copy:'COPIE',
  points: 'Points',
  welcome:'Bienvenue',
  pending:'En attente',
  off_discount: 'Hors Remise',
  redeem:'Racheter',
  code_copied_sucessfully: 'Code copié avec succès !',
  earn_point_by_referring_a_friend: 'Gagnez des points en parrainant vos amis',
  enter_your_birthday:'Entrez votre anniversaire',
  enjoy_birthday: "Profitez de l'anniversaire",
  save: 'ENREGISTRER',
  faq: 'FAQ',
  birthday_is_required: "*L'anniversaire est obligatoire",
  date: 'Date',
  activity: 'Activité',
  status: 'Statut',
  use_this_coupon_at_checkout: 'Utilisez ce code lors du paiement',
  frequently_asked_question :'Questions fréquemment posées',
  no_faq_found: 'Aucune FAQ trouvée',
  no_rewards_found: 'Aucune récompense trouvée',
  limit_reached: 'Limite atteinte. Vous ne pouvez plus utiliser ce coupon.',
  name: 'Nom',
  url: 'URL',
  loading: 'Chargement',
  user_blocked: 'Vous êtes bloqué. Vous ne pouvez pas utiliser de points pour échanger des récompenses.',
};

export default en;
