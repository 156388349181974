import React, { FunctionComponent, useState } from "react";
import { CUSTOMER_DETAILS } from "../../../../../types/Types";
import { TABS } from "../../DashboardContent";
import ActiveRewardsContent from "./child/ActiveRewardsContent";
import ActivityContent from "./child/ActivityContent";
import HomeContent from "./child/HomeContent";

type Props = {
  registerUrl: string;
  activeTab: string;
  changeActiveTab: (tab: string) => void;
  customer?: CUSTOMER_DETAILS;
  toggleOpenClose: (modal: string, data: any) => void;
};

export type HOME_SCREEN_TYPE = {
  HOME: string;
  ACTIVEREWARDS: string;
  ACTIVITY: string;
};

export const SCREENS: HOME_SCREEN_TYPE = {
  HOME: "home",
  ACTIVEREWARDS: "activeRewards",
  ACTIVITY: "activity",
};

const HomeTab: FunctionComponent<Props> = ({
  registerUrl,
  activeTab,
  changeActiveTab,
  customer,
  toggleOpenClose,
}) => {
  const [showScreen, setShowScreen] = useState<{ [key: string]: boolean }>({
    [SCREENS["HOME"]]: true,
    [SCREENS["ACTIVEREWARDS"]]: false,
    [SCREENS["ACTIVITY"]]: false,
  });

  const toggleScreen = (screen: string) => {
    switch (screen) {
      case SCREENS["ACTIVITY"]:
        return setShowScreen({
          ...showScreen,
          [SCREENS["HOME"]]: false,
          [SCREENS["ACTIVEREWARDS"]]: false,
          [SCREENS["ACTIVITY"]]: true,
        });
      case SCREENS["HOME"]:
        return setShowScreen({
          ...showScreen,
          [SCREENS["HOME"]]: true,
          [SCREENS["ACTIVEREWARDS"]]: false,
          [SCREENS["ACTIVITY"]]: false,
        });
      case SCREENS["ACTIVEREWARDS"]:
        return setShowScreen({
          ...showScreen,
          [SCREENS["HOME"]]: false,
          [SCREENS["ACTIVEREWARDS"]]: true,
          [SCREENS["ACTIVITY"]]: false,
        });
      default:
        break;
    }
  };
  return (
    <div
      className={`par-ecom-widget__nav-tab ${
        activeTab === TABS.HOME ? "nav-tab--active" : ""
      }`}
    >
      <div className="nav-tab__home">
        {showScreen[SCREENS["HOME"]] ? (
          <HomeContent
            registerUrl={registerUrl}
            changeActiveTab={changeActiveTab}
            customer={customer}
            toggleScreen={toggleScreen}
          />
        ) : null}
      </div>
      {showScreen[SCREENS["ACTIVEREWARDS"]] ? (
        <ActiveRewardsContent
          claimedRewards={customer?.claimed_rewards}
          toggleScreen={toggleScreen}
          toggleOpenClose={toggleOpenClose}
        />
      ) : null}
      {showScreen[SCREENS["ACTIVITY"]] ? (
        <ActivityContent
          activity={customer?.activities}
          toggleScreen={toggleScreen}
        ></ActivityContent>
      ) : null}
    </div>
  );
};

export default HomeTab;
