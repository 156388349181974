import moment from "moment";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { ACTIVITIES } from "../../../../../../types/Types";
import { SCREENS } from "../HomeTab";

type Props = {
  activity?: ACTIVITIES[];
  toggleScreen: (screen: string) => void;
};

const ActivityContent: FunctionComponent<Props> = ({
  activity,
  toggleScreen,
}) => {
  const { t } = useTranslation();
  const getDate = (timeStamp: string) => {
    return moment(Number(timeStamp)).format("DD-MM-YYYY");
  };

  return (
    <div className="nav-tab__home-container--three your-activity--show">
      <div className="par-ecom-widget__your-activity">
        <div className="par-ecom-widget__your-activity-title">
          <span
            className="par-ecom-widget__your-activity-title-icon"
            onClick={() => toggleScreen(SCREENS["HOME"])}
          >
            <svg
              width="6"
              height="12"
              viewBox="0 0 6 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.851447 11.9984C0.652855 11.9988 0.460396 11.9292 0.30748 11.8017C0.13366 11.6567 0.0243216 11.4481 0.0035921 11.2219C-0.0171374 10.9956 0.0524454 10.7704 0.196987 10.5958L4.00476 6.01173L0.332979 1.41906C0.190382 1.24237 0.123663 1.01578 0.147592 0.789442C0.17152 0.563109 0.284124 0.355709 0.460471 0.213163C0.638252 0.0557642 0.873229 -0.0198705 1.10879 0.00448179C1.34435 0.028834 1.55913 0.150965 1.7014 0.34145L5.80665 5.47292C6.06445 5.78851 6.06445 6.2435 5.80665 6.55909L1.5569 11.6906C1.38397 11.9005 1.12193 12.0148 0.851447 11.9984Z"
                fill="#2196f3"
              />
            </svg>
          </span>
          <h2>{t("your_activity")}</h2>
        </div>
        <div className="par-ecom-widget__table">
          <table cellSpacing="0">
            <thead>
              <th>{t("date")}</th>
              <th>{t("points")}</th>
              <th>{t("activity")}</th>
              <th>{t("status")}</th>
            </thead>
            <tbody>
              {activity?.map((activity, index) => {
                return (
                  <tr>
                    <td>{getDate(activity.created_at)}</td>
                    <td>{activity.points ?? "-"}</td>
                    <td>
                      {activity.event === "Rule"
                        ? activity.rule_title
                        : activity.event}
                    </td>
                    <td>{activity.status ?? "-"}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ActivityContent;
