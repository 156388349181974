import { useQuery, UseQueryResult } from "react-query";
import { CUSTOMER_DETAILS_TYPE, FAQ_TYPE, REDEEM_REWARDS_TYPE } from "../types/Types";
import axiosInstance from "../utils/axios";
import {
  CUSTOMER_DETAILS_API_URL,
  FAQ_API_URL,
  REDEEM_REWARDS_API_URL,
} from "./ApiConstant";

const customerDetailsRequest = async (
  email: string,
  storeUrl: string
): Promise<CUSTOMER_DETAILS_TYPE> => {
  const { data } = await axiosInstance.get<CUSTOMER_DETAILS_TYPE>(
    `${CUSTOMER_DETAILS_API_URL}?mwb_app_customer_email=${email}&mwb_app_store_url=${storeUrl}`
  );

  return data;
};

export const useCustomerDetails = (
  email: string,
  storeUrl: string
): UseQueryResult<CUSTOMER_DETAILS_TYPE> => {
  return useQuery(
    [CUSTOMER_DETAILS_API_URL],
    () => customerDetailsRequest(email, storeUrl),
    {
      refetchOnWindowFocus: false,
    }
  );
};

const redeemRewardRequest = async (
  storeUrl: string,
  customerId: string,
  rewardId: string
): Promise<REDEEM_REWARDS_TYPE> => {
  const { data } = await axiosInstance.post<REDEEM_REWARDS_TYPE>(
    `${REDEEM_REWARDS_API_URL}?mwb_app_store_url=${storeUrl}&customerId=${customerId}&rewardId=${rewardId}`
  );

  return data;
};

export const useRedeemReward = (
  storeUrl: string,
  customerId: string,
  rewardId: string
): UseQueryResult<REDEEM_REWARDS_TYPE> => {
  return useQuery(
    [REDEEM_REWARDS_API_URL],
    () => redeemRewardRequest(storeUrl, customerId, rewardId),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0
    }
  );
};

const getFAQRequest = async (storeUrl: string): Promise<FAQ_TYPE> => {
  const { data } = await axiosInstance.post<FAQ_TYPE>(
    `${FAQ_API_URL}?mwb_app_store_url=${storeUrl}`
  );

  return data;
};

export const useFaqs = (storeUrl: string): UseQueryResult<FAQ_TYPE> => {
  return useQuery(
    [FAQ_API_URL],
    () => getFAQRequest(storeUrl),
    {
      refetchOnWindowFocus: false,
    }
  );
};