export function setNavHeight() {
    var navHeight = document.getElementsByClassName('par-ecom-widget__nav-list');

    if (navHeight !== undefined && navHeight.length > 0) {
        var getNavHeight = navHeight[0].offsetHeight;
        var container = document.getElementsByClassName('par-ecom-widget__container');
        if (container.length > 0) {
            container[0].style.paddingTop = getNavHeight + 'px';
        }
    }
}

window.onload = (event) => {
    setTimeout(() => {
        setNavHeight();
    }, 2000)
};

window.addEventListener('resize', function (event) {
    setNavHeight();
}, true);