import React, { FunctionComponent } from "react";
import { useCustomerDetails } from "../../api/hooks";
import { GLOBAL_OBJECT_TYPE } from "../../types/Types";
import { DashboardContent } from "./child/DashboardContent";
import "./Dashboard.scss";

type Props = {
  globalObject: GLOBAL_OBJECT_TYPE;
};

const Dashboard: FunctionComponent<Props> = ({ globalObject }) => {
  const { data, refetch } = useCustomerDetails(
    globalObject.customerEmail,
    globalObject.storeUrl
  );

  if (data === undefined) {
    return <></>;
  }

  const customerDetails = data.data;

  const refetchCustomer = () => {
    refetch();
  };

  return (
    <>
      <DashboardContent
        globalObject={globalObject}
        customerDetails={customerDetails}
        refetchCustomer={refetchCustomer}
      />
    </>
  );
};

export default Dashboard;
