export const HOST_API = process.env.REACT_APP_API_URL || '';
export const allLangs = [
    {
      label: 'English',
      value: 'en',
      icon: '/assets/icons/flags/ic_flag_en.svg',
    },
    {
      label: 'French',
      value: 'fr',
      icon: '/assets/icons/flags/ic_flag_fr.svg',
    },
    {
      label: 'Vietnamese',
      value: 'vn',
      icon: '/assets/icons/flags/ic_flag_vn.svg',
    },
    {
      label: 'Chinese',
      value: 'cn',
      icon: '/assets/icons/flags/ic_flag_cn.svg',
    },
    {
      label: 'Arabic (Sudan)',
      value: 'ar',
      icon: '/assets/icons/flags/ic_flag_sa.svg',
    },
  ];
  
  export const defaultLang = allLangs[0]; // English