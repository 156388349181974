import React, { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRedeemReward } from "../../../../../api/hooks";
import Loader from "../../../../../components/Loader";
import { GLOBAL_OBJECT_TYPE } from "../../../../../types/Types";
import { copyTextToClipBoard } from "../../../../../utils/helper";
import { MODALS } from "../../DashboardContent";

type Props = {
  open: boolean;
  toggleOpenClose: (modal: string, data: any) => void;
  globalObject: GLOBAL_OBJECT_TYPE;
  modalData: any;
  customerId: string;
  status: string;
  refetchCustomer: () => void;
};

const DiscountModal: FunctionComponent<Props> = ({
  open,
  toggleOpenClose,
  globalObject,
  modalData,
  customerId,
  status,
  refetchCustomer,
}) => {
  const [sucessState, setSucessState] = useState(false);
  const { t } = useTranslation();
  const { data, isFetching, isFetched } = useRedeemReward(
    globalObject.storeUrl,
    customerId,
    modalData._id
  );

  if (isFetching) {
    return <Loader />;
  }
  if (isFetched && data !== undefined) {
    refetchCustomer();
  }
  return (
    <div
      className={`par-ecom-widget__modal modal-discount ${
        open ? "modal-order--open" : ""
      }`}
    >
      <h3 className="par-ecom-widget__modal-title">
        {data !== undefined ? data?.data?.reward_name : ""}
      </h3>
      <span
        className="par-ecom-widget__modal-close"
        onClick={() => toggleOpenClose(MODALS["DISCOUNTMODAL"], "")}
      >
        <svg
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="#000000"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.99943 3.06091L1.14179 0.197009C0.880587 -0.0641537 0.457099 -0.0641537 0.1959 0.197009C-0.0652999 0.458171 -0.0652999 0.881599 0.1959 1.14276L3.06021 4L0.1959 6.85724C-0.0652999 7.1184 -0.0652999 7.54183 0.1959 7.80299C0.457099 8.06415 0.880587 8.06415 1.14179 7.80299L3.99943 4.93909L6.85708 7.80299C6.98215 7.92908 7.15241 8 7.33002 8C7.50763 8 7.67789 7.92908 7.80296 7.80299C7.92907 7.67793 8 7.5077 8 7.33012C8 7.15253 7.92907 6.9823 7.80296 6.85724L4.93866 4L7.80296 1.14276C7.92907 1.0177 8 0.847472 8 0.669885C8 0.492298 7.92907 0.322065 7.80296 0.197009C7.67789 0.0709219 7.50763 0 7.33002 0C7.15241 0 6.98215 0.0709219 6.85708 0.197009L3.99943 3.06091Z"
            fill="#000000"
          />
        </svg>
      </span>
      <div className="par-ecom-widget__modal-form form-discount">
        <div className="par-ecom-widget__modal-form-group">
          {data !== undefined ? (
            <label htmlFor="Birthday">{t("use_this_coupon_at_checkout")}</label>
          ) : (
            <div className="error">{t("user_blocked")}</div>
          )}

          <div className="par-ecom-widget__modal-form-field">
            {data !== undefined ? (
              <>
                <input type="text" placeholder={data?.data?.code} />
                <input
                  type="submit"
                  className="par-ecom-widget__code-copy-btn primary-bg-color"
                  value={t("copy")}
                  onClick={() => {
                    copyTextToClipBoard(data?.data?.code);
                    setSucessState(true);
                  }}
                />

                {sucessState ? (
                  <span className="success">
                    {t("code_copied_sucessfully")}
                  </span>
                ) : null}
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiscountModal;
