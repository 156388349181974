import { FunctionComponent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import WidgetSettingContext from "../../Context/WidgetSettingContext";
import "./LoginSignUp.scss";

type Props = {
  loginUrl: string;
  registerUrl: string;
};
export const LoginSignUp: FunctionComponent<Props> = ({
  loginUrl,
  registerUrl,
}) => {
  const { t } = useTranslation();
  const [widgetToggle, setWidgetToggle] = useState<boolean>(false);
  const widgetSettings = useContext(WidgetSettingContext);
  const position = widgetSettings.colorsState.widget_position;

  const widgetToggleOnClick = () => {
    window.parent.postMessage(
      { type: "toggle", widgetToggle: !widgetToggle, position: position },
      "*"
    );
    setWidgetToggle(!widgetToggle);
  };

  const login = () => {
    window.parent.postMessage({ type: "login", url: loginUrl }, "*");
  };

  const register = () => {
    window.parent.postMessage({ type: "register", url: registerUrl }, "*");
  };

  return (
    <div>
      <div
        className={`par-ecom__container ${widgetToggle ? "widget--open" : ""}`}
      >
        <div className="par-ecom-widget-transform par-ecom-widget widget--login">
          <header className="par-ecom-widget__header primary-bg-color">
            <h1 className="par-ecom-widget__header-title">
              {t("introducing_points_and_rewards")}
            </h1>
            <div className="par-ecom-widget__icons">
              <span
                onClick={() => widgetToggleOnClick()}
                className="par-ecom-widget--close"
              >
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.99943 3.06091L1.14179 0.197009C0.880587 -0.0641537 0.457099 -0.0641537 0.1959 0.197009C-0.0652999 0.458171 -0.0652999 0.881599 0.1959 1.14276L3.06021 4L0.1959 6.85724C-0.0652999 7.1184 -0.0652999 7.54183 0.1959 7.80299C0.457099 8.06415 0.880587 8.06415 1.14179 7.80299L3.99943 4.93909L6.85708 7.80299C6.98215 7.92908 7.15241 8 7.33002 8C7.50763 8 7.67789 7.92908 7.80296 7.80299C7.92907 7.67793 8 7.5077 8 7.33012C8 7.15253 7.92907 6.9823 7.80296 6.85724L4.93866 4L7.80296 1.14276C7.92907 1.0177 8 0.847472 8 0.669885C8 0.492298 7.92907 0.322065 7.80296 0.197009C7.67789 0.0709219 7.50763 0 7.33002 0C7.15241 0 6.98215 0.0709219 6.85708 0.197009L3.99943 3.06091Z"
                    fill="white"
                  />
                </svg>
              </span>
            </div>
          </header>
          <div className="par-ecom-widget__main">
            <h2 className="par-ecom-widget__login-title">
              {t("sign_up_to")}{" "}
              <span className="text--bold">{t("store")} </span>
              {t("to_start_earning_rewards")}
            </h2>
            <div className="par-ecom-button__wraper">
              <button
                style={{ cursor: "pointer" }}
                onClick={register}
                className="par-ecom-btn btn--filled primary-bg-color"
              >
                {t("sign_up")}
              </button>
            </div>
            <span className="par-ecom-widget__help-text">
              {t("already_have_an_account")}?
            </span>
            <button
              style={{ cursor: "pointer" }}
              onClick={login}
              className="par-ecom-btn btn--outlined"
            >
              {t("login")}
            </button>
          </div>
        </div>
        <div className="par-ecom-widget__rewards-position par-ecom-widget__rewards">
          <span
            onClick={() => widgetToggleOnClick()}
            className="par-ecom-widget__rewards-icon primary-bg-color"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              height="35px"
              width="35px"
              src={widgetSettings.colorsState.widget_icon}
              alt="Points and Rewards"
            />
          </span>
        </div>
      </div>
    </div>
  );
};
