import "./App.scss";
import { setSession } from "./utils/jwt";
import "../src/containers/theme/js/custom";
import "react-toastify/dist/ReactToastify.css";
import { GLOBAL_OBJECT_TYPE } from "./types/Types";
import useGlobalObject from "./hooks/useGlobalObject";
import AppContainer from "./containers/AppContainer/AppContainer";

function App() {
  const globalObject: GLOBAL_OBJECT_TYPE | null = useGlobalObject();
  if (globalObject === null) {
    return <></>;
  }

  // const globalObject: GLOBAL_OBJECT_TYPE = {
  //   apiKey: "1e4531b59f308615aeb9b0e3443f9e03",
  //   customerEmail: "kavyatest7676@gmail.com",
  //   customerId: "7",
  //   customerUserName: "kavyatest7676",
  //   storeLoginUrl: "https://slimtrip.s1-tastewp.com/my-account/",
  //   storeRegisterUrl: "https://slimtrip.s1-tastewp.com/my-account/",
  //   storeUrl: "https://slimtrip.s1-tastewp.com/",
  //   widgetLang: "en",
  // };

  if (globalObject.apiKey) {
    setSession(globalObject.apiKey);
    localStorage.setItem("i18nextLng", globalObject.widgetLang);
  }

  return <AppContainer globalObject={globalObject} />;
}

export default App;
