import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { toast, ToastContainer } from "react-toastify";
import { UPDATE_POINTS_ON_SITE_VISIT } from "../../../api/ApiConstant";
import WidgetSettingContext from "../../../Context/WidgetSettingContext";
import {
  GLOBAL_OBJECT_TYPE,
  REWARDS_AVAILABLE,
  CUSTOMER_DETAILS,
} from "../../../types/Types";
import axiosInstance from "../../../utils/axios";
import Faq from "../../faq/Faq";
import Header from "../header/Header";
import SubHeader from "../header/SubHeader";
import EarnPointsTab from "./tabs/earnPointsTab/EarnPointsTab";
import HomeTab from "./tabs/homeTab/HomeTab";
import ActiveRewardsModal from "./tabs/modals/ActiveRewardsModal";
import BirthdayModal from "./tabs/modals/BirthdayModal";
import DiscountModal from "./tabs/modals/DiscountModal";
import OrderModal from "./tabs/modals/OrderModal";
import RewardsTab from "./tabs/rewardsTab/RewardsTab";

type Props = {
  globalObject: GLOBAL_OBJECT_TYPE;
  customerDetails: CUSTOMER_DETAILS;
  refetchCustomer: () => void;
};

export const TABS = {
  HOME: "home",
  EARNPOINTS: "earnpoints",
  REWARDS: "rewards",
  BLANK: "",
};

export type MODALTYPE = {
  ORDERMODAL: string;
  BIRTHDAYMODAL: string;
  DISCOUNTMODAL: string;
  ACTIVEREWARDSMODAL: string;
};

export const MODALS: MODALTYPE = {
  ORDERMODAL: "orderModal",
  BIRTHDAYMODAL: "birthdayModal",
  DISCOUNTMODAL: "discountModal",
  ACTIVEREWARDSMODAL: "activeRewardsModals",
};

export const DashboardContent: FunctionComponent<Props> = ({
  globalObject,
  customerDetails,
  refetchCustomer,
}) => {
  const widgetSettings = useContext(WidgetSettingContext);
  const position = widgetSettings.colorsState.widget_position;
  useEffect(() => {
    const updatePointsVisitSite = async () => {
      if (customerDetails.trigger_visit)
        try {
          const { data } = await axiosInstance.put(
            UPDATE_POINTS_ON_SITE_VISIT + customerDetails._id,
            {
              mwb_app_store_url: globalObject.storeUrl,
            }
          );
          toast(data.data.message);
          refetchCustomer();
        } catch (error: any) {}
    };
    updatePointsVisitSite();
  }, []);

  const [activeTab, setActiveTab] = useState(TABS.HOME);
  const [widgetToggle, setWidgetToggle] = useState<boolean>(false);
  const [toggleModal, setToggleModal] = useState<{ [key: string]: boolean }>({
    [MODALS["ORDERMODAL"]]: false,
    [MODALS["BIRTHDAYMODAL"]]: false,
    [MODALS["DISCOUNTMODAL"]]: false,
    [MODALS["ACTIVEREWARDSMODAL"]]: false,
  });

  const [modalData, setModalData] = useState<any>({});

  const changeActiveTab = (tab: string) => {
    showTabContent(tab);
    setActiveTab(tab);
    setToggleModal({
      [MODALS["ORDERMODAL"]]: false,
      [MODALS["BIRTHDAYMODAL"]]: false,
      [MODALS["DISCOUNTMODAL"]]: false,
      [MODALS["ACTIVEREWARDSMODAL"]]: false,
    });
  };

  const showTabContent = (tab: string) => {
    switch (tab) {
      case TABS.HOME:
        return (
          <HomeTab
            registerUrl={globalObject.storeRegisterUrl}
            activeTab={activeTab}
            changeActiveTab={changeActiveTab}
            customer={customerDetails}
            toggleOpenClose={toggleOpenClose}
          />
        );
      case TABS.EARNPOINTS:
        return (
          <EarnPointsTab
            activeTab={activeTab}
            rules={customerDetails?.rules}
            toggleOpenClose={toggleOpenClose}
          />
        );
      case TABS.REWARDS:
        return (
          <RewardsTab
            activeTab={activeTab}
            toggleOpenClose={toggleOpenClose}
            rewards={customerDetails?.rewards_available}
            refetchCustomer={refetchCustomer}
          />
        );
      case TABS.BLANK:
        return <Faq storeUrl={globalObject.storeUrl} />;
      default:
        break;
    }
  };

  const toggleOpenClose = (modal: string, data?: REWARDS_AVAILABLE) => {
    setToggleModal({ ...toggleModal, [modal]: !toggleModal[modal] });
    setModalData(data);
  };

  const toggleDisableBackground = () => {
    return toggleModal[MODALS["ORDERMODAL"]] ||
      toggleModal[MODALS["BIRTHDAYMODAL"]] ||
      toggleModal[MODALS["DISCOUNTMODAL"]] ||
      toggleModal[MODALS["ACTIVEREWARDSMODAL"]]
      ? "modal--open"
      : "";
  };

  const widgetToggleOnClick = (widgetTogg: boolean) => {
    setWidgetToggle(!widgetTogg);
    window.parent.postMessage(
      { type: "toggle", widgetToggle: !widgetTogg, position: position },
      "*"
    );
  };

  return (
    <>
      <div
        className={`par-ecom__container ${widgetToggle ? "widget--open" : ""}`}
      >
        <div
          className={`par-ecom-widget-transform par-ecom-widget widget--home ${toggleDisableBackground()}`}
        >
          <Header
            openCloseWidget={() => widgetToggleOnClick(widgetToggle)}
            userName={customerDetails?.username}
            pointBalance={customerDetails?.point_balance}
            changeActiveTab={changeActiveTab}
          />
          <div className="par-ecom-widget__main">
            <SubHeader
              activeTab={activeTab}
              changeActiveTab={changeActiveTab}
            />
            <div className="par-ecom-widget__container">
              <div className="par-ecom-widget__body">
                <div className="par-ecom-widget__nav-tab-wrap">
                  {showTabContent(activeTab)}
                </div>
              </div>
            </div>
          </div>
          <OrderModal
            open={toggleModal.orderModal}
            modalData={modalData}
            toggleOpenClose={toggleOpenClose}
          />
          {customerDetails?._id ? (
            <BirthdayModal
              storeUrl={globalObject.storeUrl}
              open={toggleModal[MODALS["BIRTHDAYMODAL"]]}
              toggleOpenClose={toggleOpenClose}
              customerId={customerDetails?._id}
              dateOfBirth={customerDetails?.date_of_birth}
              title={modalData.title}
            />
          ) : null}

          {modalData._id &&
          customerDetails?._id &&
          toggleModal[MODALS["DISCOUNTMODAL"]] ? (
            <DiscountModal
              open={toggleModal[MODALS["DISCOUNTMODAL"]]}
              toggleOpenClose={toggleOpenClose}
              globalObject={globalObject}
              modalData={modalData}
              customerId={customerDetails?._id}
              status={customerDetails.status}
              refetchCustomer={refetchCustomer}
            />
          ) : null}
          {modalData._id && toggleModal[MODALS["ACTIVEREWARDSMODAL"]] ? (
            <ActiveRewardsModal
              open={toggleModal[MODALS["ACTIVEREWARDSMODAL"]]}
              toggleOpenClose={toggleOpenClose}
              modalData={modalData}
              refetchCustomer={refetchCustomer}
            />
          ) : null}
        </div>
        <div
          id="widget-icon"
          className="par-ecom-widget__rewards-position par-ecom-widget__rewards"
        >
          <span
            className="par-ecom-widget__rewards-icon primary-bg-color"
            onClick={() => widgetToggleOnClick(widgetToggle)}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              height="35px"
              width="35px"
              src={widgetSettings.colorsState.widget_icon}
              alt="Points and Rewards"
            />
          </span>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};
